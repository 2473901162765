import React from 'react';
import './App.css';
import {Button} from "@mui/material";
import {Home} from "./Home";
import {SignIn} from "./SignIn";
import {Files} from "./Files";

function App() {
    const [route, setRoute] = React.useState<string>("home");

    return (
        <div className="App">
            <header className="home-header">
                <h1>Welcome to Super Secure Forum (SSF)!</h1>
                <Button disabled={route === "home"}  onClick={() => {setRoute("home")}}>Home</Button>
                <Button disabled={route === "signin"} onClick={() => {setRoute("signin")}}>Sign In</Button>
                <Button disabled={route === "files"} onClick={() => {setRoute("files")}}>Files</Button>
            </header>
            <hr className="separator"/>
            {route === "home" && <Home/>}
            {route === "signin" && <SignIn/>}
            {route === "files" && <Files/>}
        </div>
    );
}

export default App;
