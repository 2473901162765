import React, { useState } from "react";
import { Post } from "./types";

export function PostDisplay(props: { posts?: Post[] }) {
    const [expandedPostId, setExpandedPostId] = useState<number | null>(null);

    const togglePostVisibility = (postId: number) => {
        setExpandedPostId(prevId => (prevId === postId ? null : postId));
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            {props.posts && props.posts.map((post: Post) => (
                <div
                    key={post.id}
                    style={{
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        padding: "20px",
                        backgroundColor: "#f9f9f9",
                        cursor: "pointer",
                    }}
                    onClick={() => togglePostVisibility(post.id)}
                >
                    <h2 style={{fontSize: "1.5rem", marginBottom: "10px"}}>
                        {post.title}
                        <span style={{fontWeight: "bold", color: "blue"}}> by {post.user}</span>
                        <span style={{fontStyle: "italic", color: "green"}}> on {post.date}</span>
                    </h2>
                    {expandedPostId === post.id && (
                        <p style={{fontSize: "1.2rem"}}>{post.content}</p>
                    )}
                </div>
            ))}
            {!props.posts && <h2 style={{fontSize: "1.5rem", fontWeight: "bold", marginBottom: "10px"}}>
                Could not load posts.
            </h2>}
        </div>
    );
}
