import {Post} from "./types";

export async function getPosts(): Promise<Post[]> {
    const headers = new Headers();
    const request: RequestInfo = new Request('https://ssf-php.sead-ctf.student.iaik.tugraz.at/posts', {
        method: 'GET',
        headers: headers,
        // mode: 'no-cors',
    });
    const res = await fetch(request)
        .then (response => {
            if (!response.ok) {
                throw `Server error: [${response.status}] [${response.statusText}] [${response.url}]`;
            }
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            console.debug("Error in fetch", err);
        })
    return await res?.posts;
}