import './SignIn.css';
import {Box, Button, TextField} from "@mui/material";
import React from "react";

function SignIn() {
    const [username, setUsername] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");

    const sendRegistration = () => {
        if (username === "" || password === "") {
            alert("Username and password must not be empty");
            return;
        }
        const headers = new Headers();
        const request: RequestInfo = new Request("https://ssf-php.sead-ctf.student.iaik.tugraz.at/register?username=" + username + "&password=" + password, {
            method: 'POST',
            headers: headers,
        });
        fetch(request)
            .then (response => {
                return response.json();
            })
            .then(data => {
                if (data.message) {
                    alert(data.message);
                }
                if (data.error) {
                    alert(data.error);
                }
                setUsername("");
                setPassword("");
            })
            .catch(err => {
                console.debug("Error in POST", err);
            })
    }

    const sendLogin = async () => {
        if (username === "" || password === "") {
            alert("Username and password must not be empty");
            return;
        }
        const headers = new Headers();
        const request: RequestInfo = new Request('https://ssf-php.sead-ctf.student.iaik.tugraz.at/login?username=' + username + "&password=" + password, {
            method: 'POST',
            headers: headers,
            // mode: 'no-cors',
        });
        fetch(request)
            .then (response => {
                return response.json();
            })
            .then(data => {
                if (data.message) {
                    alert(data.message);
                }
                if (data.token) {
                    document.cookie = "token=" + data.token;
                }
                if (data.error) {
                    alert(data.error);
                }
                setUsername("");
                setPassword("");
            })
            .catch(err => {
                console.debug("Error in POST", err);
            })
    }
    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
    }
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }

    return (
        <div className="sign-in">
            <h2>Sign In</h2>
            <TextField margin="normal" label="Username" required value={username} onChange={handleUsernameChange}/>
            <br/>
            <TextField margin="normal" label="Password" required value={password} type="password" onChange={handlePasswordChange}/>
            <br/>
            <Box>
                <Button sx={{mr: 2, mt: 2}} variant="contained" onClick={sendLogin}>Log In</Button>
                <Button sx={{mt: 2}} variant="contained" disabled={true} onClick={sendRegistration}>Register</Button>
            </Box>
        </div>
    );
}

export {SignIn};