import {PostDisplay} from "./PostDisplay";
import React, {useEffect, useState} from "react";
import {getPosts} from "./postFetcher";
import {Post} from "./types";

function Home() {
    const [posts, setPosts] = useState<Post[]>([]);
    useEffect(() => {
        getPosts().then(posts => {
            setPosts(posts);
        });
    }, []);

    return (
        <div className="posts">
            <PostDisplay posts={posts}/>
        </div>
    );
}

export {Home};