import {Button} from "@mui/material";
import {getFiles} from "./fileFetcher";
import React, {useState} from "react";
import {ServerFile} from "./types";

export function Files() {
    const [files, setFiles] = useState<ServerFile[]>([]);

    const fetchFiles = async () => {
        const token = document.cookie.split("token=")[1]?.split(';')?.[0];
        if (!token) {
            alert("No access token in cookie. Login first.");
            return;
        }
        getFiles({token: token}).then(files => {
            setFiles(files);
        });
    }

    const requestFile = async (fileName: string, withPassword: boolean) => {
        const token = document.cookie.split("token=")[1]?.split(';')?.[0];
        if (!token) {
            alert("No access token in cookie. Login first.");
            return;
        }
        let filePassword = '';
        if (withPassword) {
            filePassword = prompt("Please enter the password for the file (NOT user password!)", "") ?? '';
            if (!filePassword) {
                alert("Password must not be empty");
                return;
            }
        }
        const headers = new Headers();
        const requestUrl = "https://ssf-php.sead-ctf.student.iaik.tugraz.at/file?token=" + token + "&fileName=" + fileName + "&filePassword=" + filePassword;
        const request: RequestInfo = new Request(requestUrl, {
            method: 'POST',
            headers: headers,
        });
        fetch(request)
            .then(response => {
                if ((response.status === 401) && !withPassword)
                    return requestFile(fileName, true);
                return response.json();
            })
            .then(data => {
                if (!data) return;
                if (data.message) {
                    alert(data.message);
                }
                if (data.error) {
                    alert('Error: '+data.error);
                }
                if (data.file) {
                    alert('Got file content: '+data.file);
                }
            })
            .catch(err => {
                console.debug("Error in POST", err);
            })
    }

    return (
        <div>
            {!files && <h2 style={{color: "white"}}>Could not fetch files. Check your token.</h2>}
            {(!files || files.length === 0) && <Button variant="contained" onClick={fetchFiles}>Fetch Files</Button>}
            {files?.length > 0 && files.map((file: ServerFile) => (
                <div>
                    <h2 style={{fontSize: "1.5rem", marginBottom: "10px", color: "white"}}>
                        {file.name}, owned by <span style={{fontWeight: "bold", color: "blue", marginRight: "10px"}}>{file.owner}</span>
                        <span style={{color: "grey", marginRight: "10px"}}>{file.date}</span>
                        <Button variant="outlined" onClick={() => requestFile(file.name,false)}>Request this File</Button>
                    </h2>

                </div>
            ))}
        </div>
    );
}