// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sign-in, .color-white {
    color: white;
}
.MuiFormLabel-root, .MuiInputBase-input {
    color: white !important;
}
.MuiOutlinedInput-notchedOutline {
    border-color: white !important;
}

.sign-in-buttons {
    margin: 10px;
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/SignIn.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".sign-in, .color-white {\n    color: white;\n}\n.MuiFormLabel-root, .MuiInputBase-input {\n    color: white !important;\n}\n.MuiOutlinedInput-notchedOutline {\n    border-color: white !important;\n}\n\n.sign-in-buttons {\n    margin: 10px;\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
